<template>
  <div>
    <button class="whatsapp-button" @click="showConatinerWhats()">
      <div class="whatsapp-img">
          <img id="whatsapp-img-logo" src="../assets/whatsapp_img.png" alt="" >
      </div>
    </button>

    <div class="whatsapp-container">
      <div class="whatsapp-content">
          <div class="whatapp-top-content"><p>Envie uma mensagem em nosso WhatsApp</p><button id="close-Whats-container" @click="showConatinerWhats()"><svg class="xmark-mobile" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="white"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></button></div>
          <div class="whatapp-content-message"><p>Ola!<br>Nos Envie uma messagem pelo whatsapp<br>Nos da IROD estamos prontos para lhe responder!</p></div>
          <div class="whatapp-form-container">
            <input id="whatsapp-text-message" type="text" placeholder="Mensagem">
            <button id="whatsapp-send-message" @click="sendMenssage()">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="white"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
            </button>
          </div>
      </div>
    </div>
  </div>
</template>



<script>
  
  

  export default {
      name: "WhatsAppButton",

      data() {
        return {
          //
        };
      },
  
    methods: {
      showConatinerWhats() {
        const whatsappContainer = document.querySelector(".whatsapp-container");
        if (whatsappContainer.style.display === "none" || whatsappContainer.style.display === "") {
          whatsappContainer.style.display = "block";
        } else {
          whatsappContainer.style.display = "none";
        }
      },

      sendMenssage() {
        const inputMessage = document.getElementById("whatsapp-text-message");
        let message = inputMessage.value.trim(); 
        if (message !== '') {
          message = message.replace(/ /g, "%20");
          let apiWhatsapp = `https://tintim.link/whatsapp/ada08def-f0f5-4dca-9375-3043ff4195a2/db2303b4-6e9c-4f84-be3e-94418e3bcc37`;
          window.location.href = apiWhatsapp; 
        }
      },
    },
  };

  

</script>

<style scoped>
:root {
  --primary-color: #a8c976;
  --primary-dark-color: #1a222d;
  --primary-font: 'Roboto', sans-serif;
  --second-color: #88a065;
}

.whatsapp-container {
  bottom: 8rem;
  right: 1rem;
  position: fixed;
  width: 17%;
  display: none;
}

.whatsapp-content {
  display: flex;
  justify-content: center;
  background-color: #CFCEC6;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;

}

.whatapp-form-container input {
  background-color: #FFF;
  width: 80%;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);
  border: none;
}

.whatsapp-content p {
  font-family: 'Poppins', sans-serif;;
  font-weight: bold;
  color: #FFF;
  margin-left: 1rem;
}

.whatapp-form-container button:hover {
  background-color: var(--primary-dark-color);
  transition: .5s;
}

.whatapp-form-container button {
  margin-left: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(57, 132, 122);
  padding: 1rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

#whatsapp-img-logo {
  height: 4rem;
}

.whatsapp-content button i {
  color: #FFF;
}

.whatsapp-img {
  position: fixed;
  bottom: 1rem;
  width: 5rem;
  height: 5rem;
  right: 1rem;
}

.whatapp-form-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.whatsapp-button {
  cursor: pointer;
}

.whatapp-content-message {
  background-color: white;
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.2);
}

.whatapp-content-message p {
  font-family: 'Poppins', sans-serif;
  color: black;
  margin: 1rem 1rem ;
  font-weight: lighter;
  font-size: .9rem;
}

.whatapp-top-content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgb(57, 132, 122);
  width: 100%;
  text-align: start;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whatapp-top-content p {
  font-size: .8rem;
}

.whatapp-top-content button {
  background-color: transparent;
  border: none;
  margin: 0 auto;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .whatsapp-container {
    width: 65%;
    bottom: 6rem;
  }

  .whatsapp-img {
    bottom: .5rem;
  }

  #whatsapp-img-logo {
    height: 3rem;
  }

  .xmark-mobile{
    margin-right: 10px;
  }
}
</style>